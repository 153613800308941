nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    gap: 0.5rem;
    border-radius: 2.5rem;
    backdrop-filter: blur(15px);
  }
  
  nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-light);
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  nav a:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  
  @media screen and (max-width: 520px) {
    nav {
      bottom: 3.5rem;
    }
  }
  