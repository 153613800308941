.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    /* background: var(--color-bg-variant); */
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 2px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

@keyframes blink {
    50% {
        opacity: 0.1
    }

    80% {
        opacity: .8
    }
}

.blink {
    animation: blink 1.5s linear infinite;
    font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    -webkit-text-fill-color: #CCC;
    transition: background-color 5000s ease-in-out 0s
}

/* ==================== FORM =========================== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, 
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    resize: none;
    color: #CCC;
}

#unique {
    background: transparent;
    color: #828282;
    border: 2px solid var(--color-primary-variant);
    cursor: not-allowed;
}

/* =======================  MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
    .contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* =======================  MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
    .contact__container {
        width: var(--container-width-ms);
    }
}