footer {
  background: -webkit-linear-gradient(to right,#0f2027, #203a43, #2c5364);
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  transition: background 0.3s ease;
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  font-size: 1rem;
  text-align: center;
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  80% {
    opacity: 0.5;
  }
}

.blink {
  animation: blink 1.5s linear infinite;
  font-weight: 500;
}

/* =======================  MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }
}

@media screen and (max-width: 520px) {
  .footer__copyright {
    margin-bottom: 5rem;
  }
}
