#about {
  margin-top: 1em;
}

.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  display: grid;
  place-items: center;
  box-shadow: inset 1px 1px 10px 10px rgba(11, 227, 201, 0.5), 1px 1px 15px 15px rgba(11, 227, 201, 0.5);
  transition: box-shadow 0.3s ease;
}

.about__me:hover {
  box-shadow: none;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(7deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
  transition: var(--transition);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2rem;
}

.about__card {
  text-align: center;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin-bottom: 1.5rem;
  color: var(--color-light);
}

@keyframes blink {
  50% {
    opacity: 0.1;
  }

  80% {
    opacity: 0.8;
  }
}

.blink {
  animation: blink 1.5s linear infinite;
  font-weight: 500;
}

/* =======================  MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__cards {
    margin: 2rem auto;
  }
}

/* =======================  MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
  .about__me {
    width: 80%;
    margin: 2rem 2rem;
  }

  .about__cards {
    margin: 1.5rem auto;
  }
}
