.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    padding: 1.2rem;
    border-radius: 2rem;
    /* border: none; */
    
    transition: var(--transition);
    cursor: pointer;
}

.portfolio__item:hover {
    border: transparent;
    /* background: var(--color-bg-variant); */
    transform: scale(1.1);
    border: 2px solid transparent;
    border-color: var(--color-primary-variant);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

@keyframes blink {
    50% {
        opacity: 0.1
    }

    80% {
        opacity: .8
    }
}

.blink {
    animation: blink 1.5s linear infinite;
    font-weight: 500;
}

/* =======================  MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =======================  MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}